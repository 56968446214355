import PropTypes from "prop-types";

const InputField = ({
        label,
        identifier,
        validationClass,
        value,
        handleChange,
        handleInput,
        spacingClass,
        placeholder,
        type,
        autoFocus }) => {
    return (
        <div className={ spacingClass }>
            <label htmlFor={ identifier } className="form-label">{ label }</label>
            <input name={ identifier } type={ type } className={ `form-input form-control ${ validationClass || '' }` }
                value={ value } onChange={ handleChange } onInput={ handleInput } placeholder={placeholder} autoFocus={autoFocus}
            />
        </div>
    )
}

InputField.propTypes = {
    label: PropTypes.string,
    identifier: PropTypes.string,
    type: PropTypes.string,
    validationClass: PropTypes.string,
    value: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleInput: PropTypes.func,
    spacingClass: PropTypes.string,
}

export default InputField;
