import { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { getBlogPosts } from "../../services/blog";
import Header from "../components/elements/Header";
import Footer from "../components/elements/Footer";
import { useNavigate } from "react-router-dom";

const Blog = () => {
    const [blogPosts, setBlogPosts] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
        (async() => {
			const response = await getBlogPosts();
			const blogs = response.data.items;
			blogs.map((blog) => {
				const date = new Date(blog.pubDate);
				const newDate = DateTime.fromISO(date.toISOString()).toFormat('DDD'); 
				blog.pubDate = newDate;
                return false;
			})
			setBlogPosts(blogs);
		})()
    }, [])

    return (
        <>
            <Header />
            <div className="container blog-page my-5 px-md-5">
                <div className="mt-5 pt-1 pt-lg-5 position-relative">
                    <span onClick={() => navigate(-1)} className="back-link ps-sm-2">
                        <i className="bi bi-arrow-left me-2" />
                        <span>Back to Home</span>
                    </span>
                    <h2 className="text-capitalize text-md-center text-white mb-0 fw-bold mt-4 pt-1 mt-md-0 pt-md-0 mb-5">
                        Blog
                    </h2>
                    <div className="row gy-5">
                        {
                            blogPosts.length === 0
                        ?
                            <div className="py-5">
                                <p className="pt-5 fs-2 text-center text-white">There are no blog posts yet. Check back later</p>
                            </div>
                        :
                            blogPosts.map((blog, index) => {
                                return (
                                    <div key={index} className="col-12 col-sm-6 col-lg-4">
                                        <div className="blog-card blog-card-normal d-flex flex-column mb-4">
                                            <div className="blog-card-img">
                                                <img src={blog.thumbnail} alt='blog' />
                                            </div>
                                            <div className="mt-4 mt-md-2 d-flex flex-column align-items-start justify-content-between">
                                                <div className="blog-card-body">
                                                    <p className="mb-2">{blog.pubDate}</p>
                                                    <h3 className="text-white mb-3">{blog.title}</h3>
                                                    <span dangerouslySetInnerHTML={{__html: blog.description.slice(0, 80)}} />
                                                </div>
                                                <a className="btn blog-btn mt-4" href={blog.link} target="_blank" rel="noreferrer">
                                                    Read more <i className="bi bi-arrow-up-right ms-2" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Blog;