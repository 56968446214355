import { useEffect, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom';
import Footer from '../components/elements/Footer'
import Header from '../components/elements/Header'
import { projects } from '../../data/projects';
import { routes } from '../../routes';
import { audios, videos } from './Homepage';

const Projects = () => {
    const navigate = useNavigate();
    const [pictures, setPictures] = useState([0]);

    useEffect(() => {
        const pictures = projects.filter(project => project.category !== 'Audio');
        setPictures(pictures)
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <Header />
            <div className='container px-md-5'>
                <div className="mt-5 pt-1 pt-lg-5 position-relative">
                    <span onClick={() => navigate(-1)} className="back-link ps-sm-2">
                        <i className="bi bi-arrow-left me-2" />
                        <span>Back to Home</span>
                    </span>
                    <h2 className="text-capitalize text-md-center text-white mb-0 fw-bold mt-4 pt-1 mt-md-0 pt-md-0 mb-5">
                        All Projects
                    </h2>
                    <div>
                        <h3 className='text-white mb-4 ms-lg-3'>Photography</h3>
                        <div className='row projects-photos projects-row flex-nowrap m-0 pt-0'>
                            {
								pictures.map((picture, index) => {
									return (
										<div className="col" key={index}>
											<div className="project-card">
												<img src={picture.image} alt={picture.type} className="w-100" loading="lazy" />
												<div className="mt-4">
													<p className="project-category mb-1 text--primary">{picture.category}</p>
													<Link to={`${routes.Projects}/${picture.link}`}
														className="fw-bold fs-5 project-type text-decoration-none text-white">{picture.type}
													</Link>
												</div>
											</div>
										</div>
									)
								})
							}
                        </div>
                    </div>
                    <div className='mt-5 pt-5'>
                        <h3 className='text-white mb-4 ms-lg-3'>Audio sessions</h3>
                        <div className="row projects-row g-4 mt-4 mb-5">
                            {
                                audios.map((audio, index) => {
                                    return (
                                        <div className="col-12 col-lg-3" key={index}>
                                            <div className='project-card'>
                                                <iframe src={audio.url} scrolling="no" width="100%" height="269" 
                                                    scrollbars="no" frameBorder="0" title={audio.name} style={{borderRadius: '10px'}}>
                                                </iframe>
                                                <div className="mt-3">
                                                    <p className="project-category mb-1 text--primary">{audio.name}</p>
                                                    <Link to={`${routes.Projects}/audio`}
                                                        className="fw-bold fs-5 project-type text-decoration-none text-white">Audio
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className='mt-5 pt-5'>
                        <h3 className='text-white mb-4 ms-lg-3'>Video Shoot</h3>
                        <div className="row projects-row g-4 mt-4 mb-5">
                            {
                                videos.map((video, index) => {
                                    return (
                                        <div className="col-12 col-lg-3" key={index}>
                                            <div className='project-card'>
                                                <video controls width="100%">
                                                    <source src={video.src} type="video/mp4" />
                                                    Sorry, your browser doesn't support embedded videos.
                                                </video>
                                                <div className="mt-3">
                                                    <p className="project-category mb-1 text--primary">{video.type}</p>
                                                    <Link to={`${routes.Projects}/videos`}
                                                        className="fw-bold fs-5 project-type text-decoration-none text-white">Video Shoot
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Projects
