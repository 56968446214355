import { HashLink } from "react-router-hash-link";
import { year } from "../../../helpers/functionHelpers";
import { brandLogo } from "../../../helpers/variables";

const socials = {
    facebook: 'https://www.facebook.com/cyclersstudio',
    instagram: 'https://instagram.com/cyclersstudio',
    twitter: 'https://twitter.com/cyclersstudio',
    tiktok: 'https://vm.tiktok.com/ZMNR9fQBQ/',
    youtube: 'https://www.youtube.com/channel/UCuWEoYftZapqW8jxqdPJY1Q',
    audiomack: 'https://audiomack.com/cyclers-studios'
}

const Footer = () => {

    return (
        <footer>
            <div className="container px-3 mx-1 mx-sm-auto px-lg-5">
                <div className="row align-items-center justify-content-lg-between">
                    <div className="col-12 col-md-6 mb-5 pb-3 pb-md-0 mb-md-0">
                        <div className="text-center text-md-start">
                            <img src={brandLogo} alt='logo' className="logo" />
                            <ul className="p-0 list-unstyled row mt-4 pt-2 footer-links">
                                <li className="col-6 col-sm-auto">
                                    <HashLink to="/#projects">Projects</HashLink>
                                </li>
                                <li className="col-6 col-sm-auto">
                                    <HashLink to="/#about">About</HashLink>
                                </li>
                                <li className="col-6 col-sm-auto">
                                    <HashLink to="/#services">Services</HashLink>
                                </li>
                                <li className="col-6 col-sm-auto">
                                    <HashLink to="/#team">Our Team</HashLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-4">
                        <div className="text-white ms-xl-5 ps-xl-4">
                            <h3 className="mb-3">Our Location</h3>
                            <p className="location me-2 me-sm-0">
                                4, Osayogie Street, Opp. NIPCO Filling Station, Isihor, Benin City, Edo State, Nigeria.
                            </p>
                            <ul className="d-flex list-unstyled justify-content-center justify-content-sm-start footer-socials mb-0">
                                <li>
                                    <a href={socials.twitter} target="_blank" rel="noreferrer" className="text-white me-0">
                                        <i className="bi bi-twitter"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href={socials.facebook} target="_blank" rel="noreferrer" className="text-white me-0">
                                        <i className="bi bi-facebook"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href={socials.instagram} target="_blank" rel="noreferrer" className="text-white me-0">
                                        <i className="bi bi-instagram"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href={socials.tiktok} target="_blank" rel="noreferrer" className="text-white me-0">
                                        <i className="bi bi-tiktok"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href={socials.youtube} target="_blank" rel="noreferrer" className="text-white me-0">
                                        <i className="bi bi-youtube"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href={socials.audiomack} target="_blank" rel="noreferrer" className="text-white">
                                        <i className="bi bi-music-note"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="line"></div>
                <div className="d-flex flex-column flex-md-row justify-content-md-between">
                    <ul className="p-0 list-unstyled d-flex">
                        <li className="me-3">Terms</li>
                        <li className="me-3">Privacy</li>
                        <li>Cookies</li>
                    </ul>
                    <p className="order-md-first">© {year} Cyclers Studio. All rights reserved.</p>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
