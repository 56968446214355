import { useState, useEffect } from "react";
import Header from "../components/elements/Header";
import Footer from "../components/elements/Footer";
import { Link, NavLink } from "react-router-dom";
import { routes } from "../../routes";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { DateTime } from "luxon";

import heroImage from '../../assets/images/heroImage.png'
import aboutUsImage from '../../assets/images/aboutUs.png'
import intro from '../../assets/images/intro.png'

import transparency from '../../assets/images/values/transparency.png'
import love from '../../assets/images/values/love.png'
import progressiveness from '../../assets/images/values/progressiveness.png'
import talent from '../../assets/images/values/talent.png'
import quality from '../../assets/images/values/quality.png'
import reliability from '../../assets/images/values/real.png'
import honesty from '../../assets/images/values/honesty.png'

import brand1 from '../../assets/images/brands/brand1.png'
import brand2 from '../../assets/images/brands/brand2.png'
import brand3 from '../../assets/images/brands/brand3.png'
import brand4 from '../../assets/images/brands/brand4.png'
import brand5 from '../../assets/images/brands/brand5.png'
import brand6 from '../../assets/images/brands/brand6.png'

import testimonial from '../../assets/images/testimonial.png'
import starIcon from '../../assets/images/icons/star.png'

import team1 from '../../assets/images/team/team1.jpg'
import team3 from '../../assets/images/team/team3.jpg'
import team6 from '../../assets/images/team/team6.jpg'
import team7 from '../../assets/images/team/team7.jpg'
import team8 from '../../assets/images/team/team8.jpg'

import { projects, photography } from "../../data/projects";
import YoutubeEmbed from "../components/elements/YoutubeEmbed";
import { getBlogPosts } from "../../services/blog";

const qualities = [
	`We have a 24/7 power supply and internet access.`,
	`Our accessories are of the latest tech, ranging from cameras to laptops.`,
	`We have the best team in the city with up to date knowledge in their various field.`,
	`We are actively involved in the social media space.`,
	`We have the best office space and studio house where we can handle podcast, audio mixing 
	and mastering, photography, editing and retouching.`
]

const teamMembers = [
	{
		name: 'Mary Odogbo',
		position: 'General manager',
		description: 'I’m a team player and love to carry everyone along. The process of turning ideas into real-life projects fascinates me.',
		linkedIn: 'https://www.linkedin.com/in/mary-odogbo-133046149/',
		image: team7
	},
	{
		name: 'Deborah E. Ifegwu',
		position: 'Social media manager',
		description: 'I love to refer to myself as a social butterfly and express myself by creating digital content, I’m very easygoing and a team player.',
		linkedIn: 'https://www.linkedin.com/in/deborah-ifegwu-9717b7237',
		image: team1
	},
	{
		name: 'Joseph E. Godwin',
		position: 'Marketing Manager',
		description: 'I am a calm creative and original person and work well in a setting that allows for self-expression.',
		linkedIn: 'https://www.linkedin.com/in/ekpere-joseph-347535106/',
		image: team3
	},
	{
		name: 'Clement Itiose',
		position: 'Photographer',
		description: 'I\'m a fine art photographer, I\'m an anime lover and I like trying out new things✌️',
		instagram: 'https://www.instagram.com/_amazingklem',
		image: team6
	},
	{
		name: 'Moses Joshua',
		position: 'Photographer',
		description: '',
		linkedIn: 'https://instagram.com/zion_thecreator?igshid=MmVlMjlkMTBhMg==',
		image: team8
	},
]

export const audios = [
	{
		name: 'Mamamiya',
		url: 'https://audiomack.com/embed/cyclers-studios/song/mamamiya?background=1'
	},
	{
		name: 'Radar',
		url: 'https://audiomack.com/embed/cyclers-studios/song/rader?background=1'
	},
	{
		name: 'Konji',
		url: 'https://audiomack.com/embed/cyclers-studios/song/konji?background=1'
	},
	{
		name: 'Dial my number',
		url: 'https://audiomack.com/embed/cyclers-studios/song/dial-my-number?background=1'
	},
	{
		name: 'Marry you',
		url: 'https://audiomack.com/embed/mrneroofficial/song/marry-you?background=1'
	},
	{
		name: 'Moving Train',
		url: 'https://audiomack.com/embed/mrneroofficial/song/moving-train-1?background=1'
	},
	{
		name: 'Marry You (refix)',
		url: 'https://audiomack.com/embed/mrneroofficial/song/marry-yourefix?background=1'
	}
]

export const videos = [
	{
		src: 'https://res.cloudinary.com/dyhkcvulc/video/upload/v1697841112/wedding_lng7fs_ch2ic3.mp4',
		type: 'Wedding'
	},
	{
		src: 'https://res.cloudinary.com/dyhkcvulc/video/upload/v1697843244/zoo_shoot_zmhses_rfl9ga.mp4',
		type: 'Zoo Shoot'
	},
	{
		src: 'https://res.cloudinary.com/dyhkcvulc/video/upload/v1697843561/event_cnql6j_ghdiub.mp4',
		type: 'Event'
	},
	{
		src: 'https://res.cloudinary.com/dyhkcvulc/video/upload/v1697843407/advert_si939g_if2jdt.mp4',
		type: 'Advert'
	},
	{
		src: 'https://res.cloudinary.com/dyhkcvulc/video/upload/v1697843317/about-cycler_vwp64m_fhcdkf.mp4',
		type: 'About Cycler'
	},
	{
		src: 'https://res.cloudinary.com/dyhkcvulc/video/upload/v1700601466/Copy_of_Ify_and_Tobore_court_wedding_trailer_gztpck.mp4',
		type: 'Wedding'
	},
];

const Homepage = () => {
	const [left, setLeft] = useState(0);
	const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
	const [displayedProjects, setDisplayedProjects] = useState([]);
	const [projectTab, setProjectTab] = useState('all');
	const [blogPosts, setBlogPosts] = useState([]);
	const [allProjects, setAllProjects] = useState([]);
	const [photo, setPhoto] = useState([]);
	const others = [];

	useEffect(() => {
		window.scrollTo(0, 0);
		const initialProjects = getRandomValuesFromArray(projects, 8)
		const photo = projects.slice(8, 16);
		setDisplayedProjects(initialProjects);
		setAllProjects(initialProjects);
		setPhoto(photography);
		(async() => {
			const response = await getBlogPosts();
			const blogs = response.data.items;
			blogs.map((blog) => {
				const date = new Date(blog.pubDate);
				const newDate = DateTime.fromISO(date.toISOString()).toFormat('DDD');
				blog.pubDate = newDate;
				return false;
			})
			setBlogPosts(blogs.slice(0, 3));
		})()
	}, [])

	const move = (position) => {
		const row = document.querySelector('.team-row');
		const prevBtn = document.querySelector('.btn-prev');
		const nextBtn = document.querySelector('.btn-next');
		let marginLeft;

		if (position === 'right') {
			prevBtn.classList.remove('disabled');
			setPrevBtnDisabled(false);
			if (left === -1800) {
				marginLeft = -2400;
			} else {
				marginLeft = left - 900;
			}
			row.style.marginLeft = `${marginLeft}px`;
			setLeft(marginLeft);
			if (marginLeft <= -2400) {
				row.style.marginLeft = `${marginLeft}px`;
				row.style.marginRight = `${0}px`;
				nextBtn.classList.add('disabled');
				nextBtn.disabled = true;
			}
		} else {
			if (left >= -900) {
				marginLeft = 0
				prevBtn.classList.add('disabled');
				setPrevBtnDisabled(true);
			} else if (left === -2400) {
				marginLeft = -1800;
			} else {
				marginLeft = left + 900;
			}
			row.style.marginLeft = `${marginLeft}px`;
			setLeft(marginLeft);
			nextBtn.classList.remove('disabled');
			nextBtn.disabled = false;
		}
	}

	const changeProjects = (tab) => {
		if (tab === 'photo') {
			setDisplayedProjects(photography);
		} else {
			setDisplayedProjects(allProjects);
		}
		setProjectTab(tab);
	}

	const getRandomValuesFromArray = (array, count) => {
		// Shuffle the array using Fisher-Yates algorithm
		for (let i = array.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			[array[i], array[j]] = [array[j], array[i]];
		}

		// Slice the array to get the first 'count' elements
		return array.slice(0, count);
	}

	return (
		<>
			<Header />
			<section className="herobanner">
				<div className="container">
					<div className="row align-items-center herobanner-row">
						<div className="col-lg-6">
							<div className="herobanner-text-box">
								<h1 className="herobanner-heading mb-3">
									Create &amp; Preserve <span className="text--primary">Magic</span> Memories with Cyclers
								</h1>
								<p className="herobanner-text mb-5">
									We are your best plug for quality photography, event coverage, music production, 
									videography and everything media.
								</p>
								<NavLink to={routes.BookAppointment} className='btn btn-brand-primary'>
									Book an Appointment
								</NavLink>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="ms-auto herobanner-image-box">
								<img src={heroImage} alt='Hero banner' className="w-100" />   
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="about-us" id="about">
				<div className="container">
					<div className="row align-items-center about-us-row">
						<div className="col-lg-6 order-lg-last">
							<div className="about-us-text-box">
								<h2 className="about-us-heading text--primary mb-3">
									Who we are
								</h2>
								<p className="about-us-text">
									Cyclers studio is a leading media and entertainment powerhouse delivering measurable
									results to clients through quality and satisfactory Music Production, Videography,
									Photography, artist management, event coverage, general entertainment, news and
									other media services.
								</p>
								<p className="about-us-text">
									At cyclers, we make sure to always leave our customers and clients with the 'wow'
									effect that inspires optimism and excitement through moments of music, video, photo
									captures, and other points of engagement.
								</p>
							</div>
						</div>
						<div className="col-lg-6 mt-5 mt-lg-0">
							<div className="text-center text-lg-start ms-lg-auto herobanner-image-box">
								<img src={aboutUsImage} alt='About us- photography' />
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="bg-white pt-5 values">
				<div className="container pt-md-5 mt-1 px-3 px-md-4">
					<div className="mx-lg-3">
						<img src={intro} alt='intro' />
						<span className="ms-4 ps-2">Branding Goals</span>
					</div>
					<p className="mx-lg-3 mt-3 pt-1 values-text">
						The commendable media and entertainment agency that is committed to giving you value and satisfaction
					</p>
				</div>
				<div className="container">
					<YoutubeEmbed embedId="o8NWkG-ydS8" />
				</div>
				<div className="values-images">
					<AnimationOnScroll animateIn="swipe-left" duration={8}>
						<div className="d-flex flex-nowrap">
							<img src={transparency} alt='transparency' />
							<img src={love} alt='love and goodwill' />
							<img src={progressiveness} alt='progressiveness' />
							<img src={talent} alt='talent and creativity' />
							<img src={quality} alt='quality and client satisfaction' />
							<img src={reliability} alt='reliability and dependability' />
							<img src={honesty} alt='honest and truthfulness' />
						</div>
					</AnimationOnScroll>
				</div>
				<div className="values-images values-images-bottom d-none d-lg-block">
					<AnimationOnScroll animateIn="swipe-right" duration={8}>
						<div className="values-images-bottom-box d-flex flex-nowrap justify-content-end">
							<img src={transparency} alt='transparency' />
							<img src={love} alt='love and goodwill' />
							<img src={progressiveness} alt='progressiveness' />
							<img src={talent} alt='talent and creativity' />
							<img src={quality} alt='quality and client satisfaction' />
							<img src={reliability} alt='reliability and dependability' />
							<img src={honesty} alt='honest and truthfulness' />
						</div>
					</AnimationOnScroll>
				</div>
				<div className="container text-center">
					<p className="mb-5">Thousands of people across the Country choose  Cyclers Studio</p>
					<div className="row brands align-items-center justify-content-center">
						<div className="col col-lg-auto px-lg-0">
							<img src={brand1} alt='brand logo' />
						</div>
						<div className="col col-lg-auto px-lg-0">
							<img src={brand2} alt='brand logo' />
						</div>
						<div className="col col-lg-auto px-lg-0">
							<img src={brand3} alt='brand logo' />
						</div>
						<div className="col col-lg-auto px-lg-0">
							<img src={brand4} alt='brand logo' />
						</div>
						<div className="col col-lg-auto px-lg-0">
							<img src={brand5} alt='brand logo' />
						</div>
						<div className="col col-lg-auto px-lg-0">
							<img src={brand6} alt='brand logo' />
						</div>
					</div>
				</div>
			</section>
			<section className="bg-white projects" id="projects">
				<div className="container">
					<div className="projects-section">
						<div className="d-lg-flex justify-content-between align-items-center mx-lg-3">
							<h2>Our Projects</h2>
							<ul className="ps-0 list-unstyled d-flex mt-4 mt-lg-0 pt-2 pt-lg-0 projects-nav pb-0">
								<li className={`me-3 ${projectTab === 'all' && 'active'}`} onClick={() => changeProjects('all')}>All.</li>
								<li className={`mx-3 ${projectTab === 'photo' && 'active'}`} onClick={() => changeProjects('photo')} >Photography.</li>
								<li className={`mx-3 ${projectTab === 'video' && 'active'}`} onClick={() => setProjectTab('video')} >Video shoot.</li>
								<li className={`mx-3 ${projectTab === 'audio' && 'active'}`} onClick={() => setProjectTab('audio')} >Audio session.</li>
								<li className={`ms-3 ${projectTab === 'other' && 'active'}`} onClick={() => setProjectTab('other')}>Other.</li>
							</ul>
						</div>
						<div className={`row projects-row flex-nowrap ${(projectTab !== 'all' && projectTab !== 'photo') && 'd-none'}`}>
							{
								displayedProjects.map((project, index) => {
									return (
										<div className="col" key={index}>
											<div className="project-card">
												{
													project.category !== 'Audio'
												?
													<>
														<img src={project.image} alt={project.type} className="w-100" loading="lazy" />
														<div className="mt-4">
															<p className="project-category mb-1">{project.category}</p>
															<Link to={`projects/${project.link}`}
																className="fw-bold fs-5 project-type text-decoration-none">{project.type}
															</Link>
														</div>
													</>
												:
													<>
														<iframe src={project.url} scrolling="no" width="100%" height="269" 
															scrollbars="no" frameBorder="0" title={project.name} style={{borderRadius: '10px'}}>
														</iframe>
														<div className="mt-3">
															<p className="project-category mb-1">{project.name}</p>
															<Link to={`projects/${project.link}`}
																className="fw-bold fs-5 project-type text-decoration-none">{project.category}
															</Link>
														</div>
													</>
												}
											</div>
										</div>
									)
								})
							}
						</div>
						<div className={`${projectTab === 'video' || 'd-none'}`}>
							<div className="row projects-row g-4 mt-4 mb-5">
								{ 
									videos.length === 0
								?
									<p className="fw-bold text--primary fs-2 text-center text--dark">There are no videos to display yet. Check back later</p>
								:
									videos.map((video, index) => {
										return (
											<div className="col-12 col-lg-3" key={index}>
												<div className="project-card">
													<video controls width="100%">
														<source src={video.src} type="video/mp4" />
														Sorry, your browser doesn't support embedded videos.
													</video>
													<div className="mt-3">
														<p className="project-category mb-1">{video.type}</p>
														<Link to={`projects/videos`}
															className="fw-bold fs-5 project-type text-decoration-none">Video Shoot
														</Link>
													</div>
												</div>
											</div>
										)
									})
								}
							</div>
						</div>
						<div className={`${projectTab === 'audio' || 'd-none'}`}>
							<div className="row projects-row g-4 mt-4 mb-5">
								{
									audios.map((audio, index) => {
										return (
											<div className="col-12 col-lg-3" key={index}>
												<div className="project-card">
													<iframe src={audio.url} scrolling="no" width="100%" height="269" 
														scrollbars="no" frameBorder="0" title={audio.name} style={{borderRadius: '10px'}}>
													</iframe>
													<div className="mt-3">
														<p className="project-category mb-1">{audio.name}</p>
														<Link to={`projects/audio`}
															className="fw-bold fs-5 project-type text-decoration-none">Audio
														</Link>
													</div>
												</div>
											</div>
										)
									})
								}
							</div>
						</div>
						<div className={`${projectTab === 'other' || 'd-none'}`}>
							<div className="row g-4 mt-4 mb-5">
								{ 
									others.length === 0
								?
									<p className="fw-bold text--primary fs-2 text-center text--dark">Nothing is here yet. Please check back later</p>
								:
									others.map((other, index) => {
										return (
											<div className="col-12 col-lg-6" key={index}>
												<div>
													other ${index}
												</div>
											</div>
										)
									})
								}
							</div>
						</div>
						<div className="text-center">
							<Link className='btn p-0 pb-1 projects-btn' to={routes.Projects}>View all projects.</Link>
						</div>
					</div>
				</div>
			</section>
			<section>
				<div className="container">
					<div className="row align-items-center qualities-row">
						<div className="col-lg-7 mt-5 mt-lg-0">
							<div>
								<img src={testimonial} alt='Testimonials' className="w-100" />
							</div>
						</div>
						<div className="col-lg-5 order-lg-first">
							<div className="mx-2 me-xl-5 mt-lg-5">
								<h2 className="qualities-heading text-white">
									Fewer <span className="text--primary">Meetings</span> Assure Higher Performance
								</h2>
								<ul className="list-unstyled p-0 qualities">
									{
										qualities.map((quality, index) => {
											return (
												<li key={index} className="d-flex align-items-start">
													<img src={starIcon} alt='list style' />
													<span>{quality}</span>
												</li>
											)
										})
									}
								</ul>
								<NavLink to={routes.BookAppointment} className='btn btn-brand-primary mt-3 mt-md-0'>
									Book an Appointment
								</NavLink>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="team text-white" id="team">
				<div className="container">
					<div className="team-section">
						<div className="team-header">
							<h2 className="team-heading text-white">
								We’re a <span className="text--primary">fast-growing</span> team
							</h2>
							<p>
								Cyclers team is made up of highly experienced, passionate, dynamic, knowledgeable
								and talented individuals cut across different ethnicities in Nigeria.
							</p>
						</div>
						<div className="row flex-nowrap team-row position-relative">
							{
								teamMembers.map((teamMember, index) => {
									return (
										<div className="col" key={index}>
											<div className="position-relative team-card">
												<img src={teamMember.image} alt={teamMember.name} className="bg-secondary w-100" height="480" />
												<div className="position-absolute team-member">
													<div className="team-member-info d-flex flex-column">
														<div className="d-flex justify-content-between">
															<h3 className="fw-bold team-member-name mb-3">
																{teamMember.name}
															</h3>
															<a href={teamMember.instagram || teamMember.linkedIn || teamMember.facebook} target="_blank" rel="noreferrer" className="text-white team-member-link">
																<i className="bi bi-arrow-up-right text-end" />
															</a>
														</div>
														<p className="team-member-position">{teamMember.position}</p>
														<p className="team-member-description">{teamMember.description}</p>
														<ul className="d-flex list-unstyled team-member-socials mt-2 mb-0">
															<li className={teamMember.twitter || 'd-none'}>
																<a href={teamMember.twitter} target="_blank" rel="noreferrer" className="text-white">
																	<i className="bi bi-twitter"></i>
																</a>
															</li>
															<li className={teamMember.linkedIn || 'd-none'}>
																<a href={teamMember.linkedIn} target="_blank" rel="noreferrer" className="text-white">
																	<i className="bi bi-linkedin"></i>
																</a>
															</li>
															<li className={teamMember.facebook || 'd-none'}>
																<a href={teamMember.facebook} target="_blank" rel="noreferrer" className="text-white">
																	<i className="bi bi-facebook"></i>
																</a>
															</li>
															<li className={teamMember.instagram || 'd-none'}>
																<a href={teamMember.instagram} target="_blank" rel="noreferrer" className="text-white">
																	<i className="bi bi-instagram"></i>
																</a>
															</li>
														</ul>
													</div>
												</div>
											</div>
										</div>
									)
								})
							}
						</div>
						<div className="d-none d-lg-flex mt-4 pt-2">
							<button className="icon-circle btn-prev d-flex align-items-center justify-content-center me-4 disabled" disabled={prevBtnDisabled} onClick={() => move('left')}>
								<i className="bi bi-arrow-left" />
							</button>
							<button className="icon-circle btn-next d-flex align-items-center justify-content-center" onClick={() => move('right')}>
								<i className="bi bi-arrow-right" />
							</button>
						</div>
					</div> 
				</div>
			</section>
			<section id="blog">
				<div className="container px-lg-5">
					<div className="blog">
						<div className="d-flex align-items-center justify-content-between mb-5 pb-2">
							<h2 className="team-heading text-white mb-0">
								Our <span className="text--primary">Blog</span>
							</h2>
							{
								blogPosts.length !== 0
							&&
								<NavLink to={routes.Blog} className='btn btn-brand-primary blog-view-all-btn'>
									View All
								</NavLink>
							}
						</div>
						<div className="row gy-4">
							{
								blogPosts.length === 0
							?
								<p className="pt-5 fs-1 text-center text-white fw-bold">There are no blog posts yet</p>
							:
								<>	
								{
									blogPosts[0] &&
									<div className="col-12 col-lg-5">
										<div className="blog-card">
											<div className="position-relative">
												<span className="blog-card-top-label">Hot Topic</span>
												<img src={blogPosts[0].thumbnail} alt='blog 1' className="w-100" />
												<p className="mb-0 mt-4">{blogPosts[0].pubDate}</p>
											</div>
											<div className="d-flex flex-column align-items-start justify-content-between mt-4">
												<div className="blog-card-top">
													<h3 className="fw-md-bold text-white">{blogPosts[0].title}...</h3>
													<div dangerouslySetInnerHTML={{__html: blogPosts[0].description.slice(0, 80)}} />
												</div>
												<a className="btn blog-btn mt-4" href={blogPosts[0].link} target="_blank" rel="noreferrer">
													Read more <i className="bi bi-arrow-up-right ms-2" />
												</a>
											</div>
										</div>
									</div>
								}
									<div className="col-12 col-lg-7 h-100">
										<div className="row gy-4">
											{
												blogPosts[1] &&
												<div className="col-12">
													<div className="blog-card blog-card-normal d-flex flex-column flex-md-row mb-4">
														<div className="blog-card-img-box">
															<img src={blogPosts[1].thumbnail} alt='blog 2' />
														</div>
														<div className="mt-4 mt-md-0 ms-md-4 d-flex flex-column align-items-start justify-content-between">
															<div>
																<p className="mb-2">{blogPosts[1].pubDate}</p>
																<h3 className="text-white mb-3">{blogPosts[1].title}...</h3>
																<div dangerouslySetInnerHTML={{__html: blogPosts[1].description.slice(0, 80)}} />
															</div>
															<a className="btn blog-btn mt-4" href={blogPosts[1].link} target="_blank" rel="noreferrer">
																Read more <i className="bi bi-arrow-up-right ms-2" />
															</a>
														</div>
													</div>
												</div>
											}
											{
												blogPosts[2] &&
												<div className="col-12">
													<div className="blog-card blog-card-normal d-flex flex-column flex-md-row">
														<div className="blog-card-img-box">
															<img src={blogPosts[2].thumbnail} alt='blog 3' />
														</div>
														<div className="mt-4 mt-md-0 ms-4 d-flex flex-column align-items-start justify-content-between">
															<div>
																<p className="mb-2">{blogPosts[2].pubDate}</p>
																<h3 className="text-white mb-3">{blogPosts[2].title}...</h3>
																<div dangerouslySetInnerHTML={{__html: blogPosts[2].description.slice(0, 80)}} />
															</div>
															<a className="btn blog-btn mt-4" href={blogPosts[2].link} target="_blank" rel="noreferrer">
																Read more <i className="bi bi-arrow-up-right ms-2" />
															</a>
														</div>
													</div>
												</div>
											}
										</div>
									</div>
								</>
							}
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	)
}

export default Homepage;
