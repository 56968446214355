import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { projects } from "../../data/projects";
import { audios, videos } from "./Homepage";
import Footer from "../components/elements/Footer";
import Header from "../components/elements/Header";

const ProjectCategory = () => {
    const [categoryProjects, setCategoryProjects] = useState([]);
    const [showScrollBar, setShowScrollBar] = useState(false);
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (params.category !== 'audio' && params.category !== 'video') {
            const projectsInCategory = projects.filter(project => project.link === params.category);
            projectsInCategory.length > 2 && setShowScrollBar(true);
            setCategoryProjects(projectsInCategory);
        } else {
           setShowScrollBar(true);
        }
        window.scrollTo(0, 0);
    }, [params.category])

    return (
        <>
            <Header />
            <div className="project-category pt-md-2">
                <div className="container px-md-5">
                    <div className="mt-5 pt-1 pt-lg-5 position-relative">
                        <span onClick={() => navigate(-1)} className="back-link ps-sm-2">
                            <i className="bi bi-arrow-left me-2" />
                            <span>Back</span>
                        </span>
                        <h2 className="text-capitalize text-md-center text-white mb-0 fw-bold mt-4 pt-1 mt-md-0 pt-md-0">
                            {params.category.replace('-', ' ')}
                        </h2>
                    </div>
                    <div className={`row project-category-row ${showScrollBar || 'hide-scrollbar'}`}>
                        {
                            params.category !== 'audio' &&  params.category !== 'videos'
                        ?
                            categoryProjects.map((project, index) => {
                                return (
                                    <div key={index} className="col">
                                        <div className="project-category-card">
                                            <img src={project.image} alt={project.category} className="w-100" loading="lazy" />
                                        </div>
                                    </div>
                                )
                            })
                        :
                            params.category === 'audio'
                        ?
                            audios.map((audio, index) => {
                                return (
                                    <div className="col" key={index}>
                                        <div className="project-category-card">
                                            <iframe src={audio.url} scrolling="no" width="100%" height="269" 
                                                scrollbars="no" frameBorder="0" title={audio.name} style={{borderRadius: '10px'}}>
                                            </iframe>
                                        </div>
                                    </div>
                                )
                            })
                        :
                            videos.map((video, index) => {
                                return (
                                    <div className="col" key={index}>
                                        <div className="project-category-card">
                                            <video controls width="100%">
                                                <source src={video.src} type="video/mp4" />
                                                Sorry, your browser doesn't support embedded videos.
                                            </video>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default ProjectCategory;
