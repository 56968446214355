import React from 'react'

const Toast = ({toastColor, toastType, toastMessage}) => {
    return (
        <div className="position-fixed top-0 end-0 p-3" style={{'zIndex': 11}}>
            <div className={`toast align-items-center ${toastType} border-0 text-white  ${toastColor}`} role="alert" aria-live="assertive" aria-atomic="true">
                <div className="d-flex">
                    <div className={`toast-body`}>
                        {toastMessage}
                    </div>
                    <button type="button" className="btn-close me-2 m-auto text-white" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
            </div>
        </div>
    )
}

export default Toast
