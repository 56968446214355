import React from 'react'
import { Link } from 'react-router-dom';
import { brandLogo } from '../../helpers/variables';
import { routes } from '../../routes';

const PageNotFound = () => {
    return (
        <div className='d-flex align-items-center text--primary justify-content-center page-not-found'>
            <div className='text-center'>
                <img src={brandLogo} alt='logo' className="logo mb-3" />
                <span className='fw-bold fs-1 d-block text-white'>Page Not Found </span>
                <span className='d-block'>
                This page does not exist. Click
                <Link className='text-white text-decoration-none mx-1' to={routes.Homepage}>here</Link>
                to return to Homepage
            </span>
            </div>
        </div>
    )
}

export default PageNotFound;
