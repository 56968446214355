import React from 'react'
import { createRoot } from 'react-dom/client';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import 'bootstrap-icons/font/bootstrap-icons.css'
import './styles.scss'
import './index.css'
import App from './App'

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
