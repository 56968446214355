import { useState, useRef, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import bookSessionImage from "../../assets/images/book-session.png"
import { routes } from "../../routes";
import InputField from "../components/forms/InputField";
import { year } from "../../helpers/functionHelpers";
import { emailKeys, whatsappNumber, recaptchaKey, brandLogo } from "../../helpers/variables";
import emailjs from '@emailjs/browser';
import SuccessToast from "../components/elements/Toast";
import ErrorToast from "../components/elements/Toast";
import ReCAPTCHA from "react-google-recaptcha";

const emptyUser = {
    name: '',
    email: '',
    phoneNumber: '',
    description: ''
}

const BookAppointment = () => {
    const form = useRef();
    const reCaptchaRef = useRef();
    const [user, setUser] = useState(emptyUser);
    const [formDisabled, setFormDisabled] = useState(true);
    // const [recaptcha, setRecaptcha] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const handleChange = (e) => {
        const { name, value } = e.target

        setUser({
            ...user,
            [name]: value
        })

        let disabled = isDisabled();
        disabled ? setFormDisabled(true) : setFormDisabled(false)
    }

    const isDisabled = () => {
        if (!!(user.name !== '' && user.email !== '' && user.phoneNumber !== '' && user.description !== '')) {
            return false;
        }
        return true;
    }

    const resetForm = () => {
        setUser(emptyUser);
    }

    const handleSubmit = async(e) => {
        e.preventDefault();
        await reCaptchaRef.current.executeAsync();
        emailjs.sendForm(emailKeys.SERVICE_ID, emailKeys.TEMPLATE_ID, form.current, emailKeys.PUBLIC_KEY)
        .then(() => {
            let message = `Name: ${user.name}\nEmail: ${user.email}\nPhone Number: ${user.phoneNumber}\nDescription: ${user.description}`
            let url = `https://api.whatsapp.com//send?phone=${whatsappNumber}`;
    
            // Appending the message to the URL by encoding it
            url += `&text=${encodeURI(message)}&app_absent=0`;
    
            window.open(url);
            resetForm();
            displayToast('toast-success');
            setTimeout(() => {
                navigate(routes.Homepage);
            }, 5000)
        }, (error) => {
            displayToast('toast-error');
            resetForm();
            setTimeout(() => {
                hideToast('toast-error')
            }, 5000)
        });
    }

    const displayToast = (type) => {
        const toast = document.querySelector(`.${type}`);
        toast.classList.add('show')
    }

    const hideToast = (type) => {
        const toast = document.querySelector(`.${type}`);
        if (toast.classList.contains('show')) {
            toast.classList.remove('show')
        }
    }

    return (
        <>
            <div className="row no-gutter">
                <div className="col-12 col-custom-slim">
                    <div className="appointment-form container container-lg-fluid">
                        <div className="text-center text-lg-start">
                            <NavLink className="navbar-brand" to={routes.Homepage}>
                                <img src={brandLogo} alt="brand logo" className="book-appointment-logo" />
                            </NavLink>
                        </div>

                        <div className="mt-5 pt-1 pt-lg-5">
                            <Link to={routes.Homepage} className="back-link text-decoration-none">
                                <i className="bi bi-arrow-left me-2" />
                                <span>Back to Home</span>
                            </Link>
                        </div>

                        <h2 className="fw-bold">Booking <span className="text--primary">Form</span></h2>
                        <p>Get the best service now</p>
                        <form ref={form} className="mt-4 pt-2 pb-5" onSubmit={handleSubmit}>
                            <InputField 
                                label='Name*'
                                identifier='name'
                                type='text'
                                placeholder='Enter your name'
                                value={user.name}
                                spacingClass="input-box"
                                handleChange={handleChange}
                                autoFocus
                            />
                            <InputField 
                                label='Email*'
                                identifier='email'
                                type='email'
                                placeholder='Enter your email'
                                value={user.email}
                                spacingClass="input-box"
                                handleChange={handleChange}
                            />
                            <InputField 
                                label='Phone number*'
                                identifier='phoneNumber'
                                type='number'
                                placeholder='Enter your phone number'
                                value={user.phoneNumber}
                                spacingClass="input-box"
                                handleChange={handleChange}
                            />
                            <div className="input-description">
                                <label className="form-label" htmlFor="description">Description*</label>
                                <textarea id="description" name="description" value={user.description} className="form-input form-control"
                                    placeholder="Enter description" onChange={handleChange}
                                ></textarea>
                            </div>
                            <ReCAPTCHA
                                sitekey={recaptchaKey}
                                className="mt-3"
                                size="invisible"
                                ref={reCaptchaRef}
                            />
                            <button className='btn btn-brand-primary mt-5' disabled={formDisabled}>
                                Book Appointment
                            </button>
                        </form>
                        <p className="text-center mt-5 pt-5 copyright mb-0">© Cyclers Studio {year}</p>
                    </div>
                </div>
                <div className="d-none d-lg-block col-custom-wide">
                    <div className="h-100">
                        <img src={bookSessionImage} alt="book a session" className="w-100 h-100" />
                    </div>
                </div>
            </div>
            <SuccessToast 
                toastColor={'bg-success'}
                toastMessage={'Message sent successfully'}
                toastType={'toast-success'}
            />
            <ErrorToast 
                toastColor={'bg-danger'}
                toastMessage={'An error occurred. Please try again'}
                toastType={'toast-error'}
            />
        </>
    )
}

export default BookAppointment;