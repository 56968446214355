import project1 from '../assets/images/projects/project1.png'
import project2 from '../assets/images/projects/project2.png'
import project3 from '../assets/images/projects/project3.png'
import project4 from '../assets/images/projects/project4.png'
import project5 from '../assets/images/projects/project5.png'
import project6 from '../assets/images/projects/project6.png'
import project7 from '../assets/images/projects/project7.png'
import project8 from '../assets/images/projects/project8.png'

import concert1 from '../assets/images/projects/concert/concert1.jpg'
import concert2 from '../assets/images/projects/concert/concert2.jpg'
import concert3 from '../assets/images/projects/concert/concert3.jpg'
import concert4 from '../assets/images/projects/concert/concert4.jpg'
import concert5 from '../assets/images/projects/concert/concert5.jpg'
import concert6 from '../assets/images/projects/concert/concert6.jpg'
import concert7 from '../assets/images/projects/concert/concert7.jpg'
import concert8 from '../assets/images/projects/concert/concert8.jpg'
import concert9 from '../assets/images/projects/concert/concert9.jpg'
import concert10 from '../assets/images/projects/concert/concert10.jpg'
import concert11 from '../assets/images/projects/concert/concert11.jpg'
import concert12 from '../assets/images/projects/concert/concert12.jpg'
import concert13 from '../assets/images/projects/concert/concert13.jpg'
import concert14 from '../assets/images/projects/concert/concert14.jpg'
import concert15 from '../assets/images/projects/concert/concert15.jpg'

import event1 from '../assets/images/projects/events/event1.jpg'
import event2 from '../assets/images/projects/events/event2.jpg'
import event3 from '../assets/images/projects/events/event3.jpg'
import event4 from '../assets/images/projects/events/event4.jpg'
import event5 from '../assets/images/projects/events/event5.jpg'
import event6 from '../assets/images/projects/events/event6.jpg'
import event7 from '../assets/images/projects/events/event7.jpg'
import event8 from '../assets/images/projects/events/event8.jpg'

import prewedding1 from '../assets/images/projects/prewedding/prewedding1.jpg'
import prewedding2 from '../assets/images/projects/prewedding/prewedding2.jpg'
import prewedding3 from '../assets/images/projects/prewedding/prewedding3.jpg'
import prewedding4 from '../assets/images/projects/prewedding/prewedding4.jpg'
import prewedding5 from '../assets/images/projects/prewedding/prewedding5.jpg'
import prewedding6 from '../assets/images/projects/prewedding/prewedding6.jpg'
import prewedding7 from '../assets/images/projects/prewedding/prewedding7.jpg'
import prewedding8 from '../assets/images/projects/prewedding/prewedding8.jpg'

import studio1 from '../assets/images/projects/studio-portraits/studio1.jpg'
import studio2 from '../assets/images/projects/studio-portraits/studio2.jpg'
import studio3 from '../assets/images/projects/studio-portraits/studio3.jpg'

import location1 from '../assets/images/projects/location-shoot/location1.jpg'
import location2 from '../assets/images/projects/location-shoot/location2.jpg'
import location3 from '../assets/images/projects/location-shoot/location3.jpg'
import location4 from '../assets/images/projects/location-shoot/location4.jpg'
import location5 from '../assets/images/projects/location-shoot/location5.jpg'
import location6 from '../assets/images/projects/location-shoot/location6.jpg'
import location7 from '../assets/images/projects/location-shoot/location7.jpg'
import location8 from '../assets/images/projects/location-shoot/location8.jpg'
import location9 from '../assets/images/projects/location-shoot/location9.jpg'
import location10 from '../assets/images/projects/location-shoot/location10.jpg'
import location11 from '../assets/images/projects/location-shoot/location11.jpg'
import location12 from '../assets/images/projects/location-shoot/location12.jpg'

import family1 from '../assets/images/projects/family/family1.jpg'
import family2 from '../assets/images/projects/family/family2.jpg'
import family3 from '../assets/images/projects/family/family3.jpg'
import family4 from '../assets/images/projects/family/family4.jpg'
import family5 from '../assets/images/projects/family/family5.jpg'
import family6 from '../assets/images/projects/family/family6.jpg'
import family7 from '../assets/images/projects/family/family7.jpg'

import protrait1 from '../assets/images/projects/protraits/protrait1.jpg'
import protrait2 from '../assets/images/projects/protraits/protrait2.jpg'
import protrait3 from '../assets/images/projects/protraits/protrait3.jpg'
import protrait4 from '../assets/images/projects/protraits/protrait4.jpg'
import protrait5 from '../assets/images/projects/protraits/protrait5.jpg'
import protrait6 from '../assets/images/projects/protraits/protrait6.jpg'
import protrait7 from '../assets/images/projects/protraits/protrait7.jpg'
import protrait8 from '../assets/images/projects/protraits/protrait8.jpg'
import protrait9 from '../assets/images/projects/protraits/protrait9.jpg'

import wedding1 from '../assets/images/projects/weddings/wedding1.jpg'
import wedding2 from '../assets/images/projects/weddings/wedding2.jpg'
import wedding3 from '../assets/images/projects/weddings/wedding3.jpg'
import wedding4 from '../assets/images/projects/weddings/wedding4.jpg'
import wedding5 from '../assets/images/projects/weddings/wedding5.jpg'
import wedding6 from '../assets/images/projects/weddings/wedding6.jpg'

export const photography = [
    {
        category: 'Photography',
        type: 'Family',
        link: 'family',
        image: family4
    },
    {
        category: 'Photography',
        type: 'Protraits',
        link: 'protriats',
        image: protrait4
    },
    {
        category: 'Photography',
        type: 'Weddings',
        link: 'weddings',
        image: wedding3
    },
    {
        category: 'Photography',
        type: 'Event management',
        image: project1,
        link: 'events'
    },
    {
        category: 'Photography',
        type: 'Modelling',
        image: project2,
        link: 'modelling'
    },
    {
        category: 'Videoshoot',
        type: 'Lagos Documentry',
        image: project3,
        link: 'documentary'
    },
    {
        category: 'Photography',
        type: 'Studio Portraits',
        image: project4,
        link: 'studio-portraits'
    },
    {
        category: 'Photography',
        type: 'Location Shoot',
        image: project7,
        link: 'location-shoot'
    },
]
export const projects = [
    {
        category: 'Photography',
        type: 'Family',
        link: 'family',
        image: family1
    },
    {
        category: 'Photography',
        type: 'Family',
        link: 'family',
        image: family2
    },
    {
        category: 'Photography',
        type: 'Family',
        link: 'family',
        image: family3
    },
    {
        category: 'Photography',
        type: 'Family',
        link: 'family',
        image: family4
    },
    {
        category: 'Photography',
        type: 'Family',
        link: 'family',
        image: family5
    },
    {
        category: 'Photography',
        type: 'Family',
        link: 'family',
        image: family6
    },
    {
        category: 'Photography',
        type: 'Family',
        link: 'family',
        image: family7
    },
    {
        category: 'Photography',
        type: 'Protraits',
        link: 'protriats',
        image: protrait1
    },
    {
        category: 'Photography',
        type: 'Protraits',
        link: 'protriats',
        image: protrait2
    },
    {
        category: 'Photography',
        type: 'Protraits',
        link: 'protriats',
        image: protrait3
    },
    {
        category: 'Photography',
        type: 'Protraits',
        link: 'protriats',
        image: protrait4
    },
    {
        category: 'Photography',
        type: 'Protraits',
        link: 'protriats',
        image: protrait5
    },
    {
        category: 'Photography',
        type: 'Protraits',
        link: 'protriats',
        image: protrait6
    },
    {
        category: 'Photography',
        type: 'Protraits',
        link: 'protriats',
        image: protrait7
    },
    {
        category: 'Photography',
        type: 'Protraits',
        link: 'protriats',
        image: protrait8
    },
    {
        category: 'Photography',
        type: 'Protraits',
        link: 'protriats',
        image: protrait9
    },
    {
        category: 'Photography',
        type: 'Weddings',
        link: 'weddings',
        image: wedding1
    },
    {
        category: 'Photography',
        type: 'Weddings',
        link: 'weddings',
        image: wedding2
    },
    {
        category: 'Photography',
        type: 'Weddings',
        link: 'weddings',
        image: wedding3
    },
    {
        category: 'Photography',
        type: 'Weddings',
        link: 'weddings',
        image: wedding4
    },
    {
        category: 'Photography',
        type: 'Weddings',
        link: 'weddings',
        image: wedding5
    },
    {
        category: 'Photography',
        type: 'Weddings',
        link: 'weddings',
        image: wedding6
    },
    {
		category: 'Photography',
		type: 'Event management',
		image: project1,
		link: 'events'
	},
	{
		category: 'Photography',
		type: 'Modelling',
		image: project2,
		link: 'modelling'
	},
    {
        category: 'Audio',
		name: 'Marry you',
		url: 'https://audiomack.com/embed/mrneroofficial/song/marry-you?background=1',
        link: 'audio'
	},
	{
		category: 'Videoshoot',
		type: 'Lagos Documentry',
		image: project3,
		link: 'documentary'
	},
	{
		category: 'Photography',
		type: 'Studio Portraits',
		image: project4,
		link: 'studio-portraits'
	},
	{
		category: 'Photography',
		type: 'Prewedding shoot',
		image: project5,
		link: 'prewedding-shoot'
	},
	{
		category: 'Event',
		type: 'Concert',
		image: project6,
		link: 'concert'
	},
	{
		category: 'Photography',
		type: 'Location Shoot',
		image: project7,
		link: 'location-shoot'
	},
	{
		category: 'Photography',
		type: 'Protest',
		image: project8,
		link: 'protest'
	},
    {
        category: 'Photography',
		type: 'Concert',
        link: 'concert',
        image: concert1
    },
    {
        category: 'Photography',
		type: 'Concert',
        link: 'concert',
        image: concert2
    },
    {
        category: 'Photography',
		type: 'Concert',
        link: 'concert',
        image: concert3
    },
    {
        category: 'Photography',
		type: 'Concert',
        link: 'concert',
        image: concert4
    },
    {
        category: 'Photography',
		type: 'Concert',
        link: 'concert',
        image: concert5
    },
    {
        category: 'Photography',
		type: 'Concert',
        link: 'concert',
        image: concert6
    },
    {
        category: 'Photography',
		type: 'Concert',
        link: 'concert',
        image: concert7
    },
    {
        category: 'Photography',
		type: 'Concert',
        link: 'concert',
        image: concert8
    },
    {
        category: 'Photography',
		type: 'Concert',
        link: 'concert',
        image: concert9
    },
    {
        category: 'Photography',
		type: 'Concert',
        link: 'concert',
        image: concert10
    },
    {
        category: 'Photography',
		type: 'Concert',
        link: 'concert',
        image: concert11
    },
    {
        category: 'Photography',
		type: 'Concert',
        link: 'concert',
        image: concert12
    },
    {
        category: 'Photography',
		type: 'Concert',
        link: 'concert',
        image: concert13
    },
    {
        category: 'Photography',
		type: 'Concert',
        link: 'concert',
        image: concert14
    },
    {
        category: 'Photography',
		type: 'Concert',
        link: 'concert',
        image: concert15
    },
    {
        category: 'Photography',
		type: 'Events',
        link: 'events',
        image: event1
    },
    {
        category: 'Photography',
		type: 'Events',
        link: 'events',
        image: event2
    },
    {
        category: 'Photography',
		type: 'Events',
        link: 'events',
        image: event3
    },
    {
        category: 'Photography',
		type: 'Events',
        link: 'events',
        image: event4
    },
    {
        category: 'Photography',
		type: 'Events',
        link: 'events',
        image: event5
    },
    {
        category: 'Photography',
		type: 'Events',
        link: 'events',
        image: event6
    },
    {
        category: 'Photography',
		type: 'Events',
        link: 'events',
        image: event7
    },
    {
        category: 'Photography',
		type: 'Events',
        link: 'events',
        image: event8
    },
    {
        category: 'Photography',
		type: 'Prewedding shoot',
        link: 'prewedding-shoot',
        image: prewedding1
    },
    {
        category: 'Photography',
		type: 'Prewedding shoot',
        link: 'prewedding-shoot',
        image: prewedding2
    },
    {
        category: 'Photography',
		type: 'Prewedding shoot',
        link: 'prewedding-shoot',
        image: prewedding3
    },
    {
        category: 'Photography',
		type: 'Prewedding shoot',
        link: 'prewedding-shoot',
        image: prewedding4
    },
    {
        category: 'Photography',
		type: 'Prewedding shoot',
        link: 'prewedding-shoot',
        image: prewedding5
    },
    {
        category: 'Photography',
		type: 'Prewedding shoot',
        link: 'prewedding-shoot',
        image: prewedding6
    },
    {
        category: 'Photography',
		type: 'Prewedding shoot',
        link: 'prewedding-shoot',
        image: prewedding7
    },
    {
        category: 'Photography',
		type: 'Prewedding shoot',
        link: 'prewedding-shoot',
        image: prewedding8
    },
    {
        category: 'Photography',
		type: 'Prewedding shoot',
        link: 'studio-portraits',
        image: studio1
    },
    {
        category: 'Photography',
		type: 'Prewedding shoot',
        link: 'studio-portraits',
        image: studio2
    },
    {
        category: 'Photography',
		type: 'Prewedding shoot',
        link: 'studio-portraits',
        image: studio3
    },
    {
        category: 'Photography',
		type: 'Location Shoot',
        link: 'location-shoot',
        image: location1
    },
    {
        category: 'Photography',
		type: 'Location Shoot',
        link: 'location-shoot',
        image: location2
    },
    {
        category: 'Photography',
		type: 'Location Shoot',
        link: 'location-shoot',
        image: location3
    },
    {
        category: 'Photography',
		type: 'Location Shoot',
        link: 'location-shoot',
        image: location4
    },
    {
        category: 'Photography',
		type: 'Location Shoot',
        link: 'location-shoot',
        image: location5
    },
    {
        category: 'Photography',
		type: 'Location Shoot',
        link: 'location-shoot',
        image: location6
    },
    {
        category: 'Photography',
		type: 'Location Shoot',
        link: 'location-shoot',
        image: location7
    },
    {
        category: 'Photography',
		type: 'Location Shoot',
        link: 'location-shoot',
        image: location8
    },
    {
        category: 'Photography',
		type: 'Location Shoot',
        link: 'location-shoot',
        image: location9
    },
    {
        category: 'Photography',
		type: 'Location Shoot',
        link: 'location-shoot',
        image: location10
    },
    {
        category: 'Photography',
		type: 'Location Shoot',
        link: 'location-shoot',
        image: location11
    },
    {
        category: 'Photography',
		type: 'Location Shoot',
        link: 'location-shoot',
        image: location12
    }
]