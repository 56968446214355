import { routes } from './index';
import Homepage from '../views/pages/Homepage';
import Blog from '../views/pages/Blog';
import BookAppointment from '../views/pages/BookAppointment';
import ProjectCategory from '../views/pages/ProjectCategory';
import Projects from '../views/pages/Projects';
import PageNotFound from '../views/pages/PageNotFound';

export const Pages = [
    { 'component': <Homepage />, 'link': routes.Homepage, 'exact': true },
    { 'component': <Blog />, 'link': routes.Blog },
    { 'component': <BookAppointment />, 'link': routes.BookAppointment },
    { 'component': <ProjectCategory />, 'link': routes.ProjectCategory },
    { 'component': <Projects />, 'link': routes.Projects },
    { 'component': <PageNotFound />, 'link': routes.PageNotFound }
]
