import { NavLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { brandLogo } from '../../../helpers/variables';
import { routes } from '../../../routes';

const navHashLinks = [
    {
        name: 'About us',
        link: 'about'
    },
    {
        name: 'Projects',
        link: 'projects'
    },
    {
        name: 'Our Team',
        link: 'team'
    },
    {
        name: 'Blog',
        link: 'blog'
    }
]

const Header = () => {
    return (
        <header className='header'>
            <nav className='navbar navbar-expand-lg bg-transparent'>
                <div className='container px-xl-5'>
                    <NavLink className="navbar-brand px-0 ps-xl-3" to={routes.Homepage}>
                        <img src={brandLogo} alt="brand logo" />
                    </NavLink>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
                        <i className="bi bi-list text-white" />
                    </button>
                    <div className="collapse navbar-collapse" id="navbar">
                        <ul className='navbar-nav ms-auto'>
                            <li>
                                <NavLink to={routes.Homepage} className='btn'>
                                    Home
                                </NavLink>
                            </li>
                            {
                                navHashLinks.map((navHashLink, index) => {
                                    return (
                                        <li key={index}>
                                            <HashLink to={`${routes.Homepage}#${navHashLink.link}`} className='btn'>
                                                {navHashLink.name}
                                            </HashLink>
                                        </li>
                                    )
                                })
                            }
                            <li>
                                <NavLink to={routes.BookAppointment} className='btn ms-4 ms-lg-3 btn-brand-primary me-xl-3'>
                                    Book an Appointment
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
       </header>
    )
}

export default Header;
